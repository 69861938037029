import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const WorldCleanupDay = () => {
    let query = graphql`
    query {
      wcdImage: file(relativePath: { eq: "worldcleanupday.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 300, maxHeight: 170, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
        }
      }
    }
  `;
  const data = useStaticQuery(query);
  const sources = [
    data.wcdImage.childImageSharp.fluid,
    {
      ...data.wcdImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  return <Img fluid={sources} />
}

export default WorldCleanupDay