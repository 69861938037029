import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import XmasBox from "../components/projecten/xmasbox"
import PopupMuseum from "../components/projecten/popup-museum"
import TuttenBoom from "../components/projecten/tuttenboom"
import WorldCleanupDay from "../components/projecten/worldcleanupday"

const DitIsJCI = () => (
  <Layout>
    <SEO title="Dit is jci dendermonde" />
    <div class="pt-40">
      <div class="container mt-4 px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <h2>Projecten</h2>
        <p>Binnen JCI organiseren we tal van projecten die een impact op onze omgeving hebben.
        Hieronder volgen enkele projecten die we door de jaren heen hebben uitgevoerd.</p>
        <p class="mt-4 flex w-full space-between">
          <XmasBox></XmasBox>
          <p class="ml-4">
            <h2>JCI Xmas Box</h2>
            Met dit project slaan we de handen in elkaar om kinderen, die in minder gunstige omstandigheden opgroeien, een mooi kerstgeschenk te kunnen aanbieden. 
            <br /><a class="text-sm text-blue-500" href="https://www.facebook.com/jcixmasbox">Meer info</a>
          </p>
        </p>
        <p class="mt-4 flex w-full justify-between">
          <p class="mr-4">
            <h2>Ros Beiaardpop-upmuseum</h2>
            In dit project gingen we op zoek naar emotionele verhalen rond het Ros Beiaard. 
            Daartoe werkten we samen met de dienst toerisme van de stad Dendermonde. 
            Via dit project kwamen we in contact met andere personen en verenigingen in en rond Dendermonde. 
            Er kwam zelfs reactie vanop Linkeroever in Antwerpen.

            <br /><a class="text-sm text-blue-500" href="https://www.rosbeiaardpopupmuseum.be/">Meer info</a>
          </p>
          <PopupMuseum></PopupMuseum>
        </p>
        <p class="mt-4 flex w-full space-between">
          <WorldCleanupDay></WorldCleanupDay>
          <p class="ml-4">
            <h2>World Cleanup Day</h2>
            Zwerfvuil is een ernstig probleem in onze samenleving en als vereniging vinden we dit een interessant thema om rond te werken. 
            Elk jaar trachten we samen met de stad Dendermonde dit evenement te organiseren. Tijdens deze dag vinden er opruimacties in meer dan 180 landen plaats. 
            Dit is de grootste burgeractie ter wereld. Via dit initiatief proberen we mensen bewust te maken van zwerfvuil en zorg voor de natuur. 
            <br /><a class="text-sm text-blue-500" href="https://www.facebook.com/World-Cleanup-Day-Dendermonde-103157701474685">Meer info</a>
          </p>
        </p>
        <p class="mt-4 flex w-full justify-between">
          <p class="mr-4">
            <h2>Tuttenboom</h2>
            JCI Dendermonde verleende ook haar medewerking aan de Tuttenboom,
            waarbij kinderen hun fopspeen aan een boom kunnen ophangen en hier afscheid van nemen. 
            In Dendermonde bestaat de boom uit ijzer en is hij dus perfect verplaatsbaar.  

            <br /><a class="text-sm text-blue-500" href="https://www.facebook.com/Tuttenboom-Dendermonde-1708857979394081">Meer info</a>
          </p>
          <TuttenBoom></TuttenBoom>
        </p>
      </div>
    </div>
  </Layout>
)

export default DitIsJCI